import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorsSvgService {


  one:any;
  two:any;

  ilustrationOne:any;
  illustrationTwo:any;

  constructor() { }
}
