import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { LoadingController } from '@ionic/angular';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EnvService } from './env.service';
import { Storage } from '@ionic/storage';
import { Platform, ToastController } from '@ionic/angular';
import { AppStorageKey } from '../static';
import { RecognizeService } from 'src/app/services/recognize.service';
import { SurveyService } from 'src/app/services/survey.service';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { ModalController } from '@ionic/angular';
import { ViewEmployeeVoicePage } from 'src/app/modules/employee-voice/view-employee-voice/view-employee-voice.page';
import { ReceiveRecognizePage } from 'src/app/modules/recognize-team/receive-recognize/receive-recognize.page';
import { SurveyNotAvailableComponent } from '../modals/survey-not-available/survey-not-available.component';
import { NavigationExtras, Router } from '@angular/router';
import { RecognizeByPage } from '../modules/recognize-team/recognize-by/recognize-by.page';
import { Badge } from '@ionic-native/badge/ngx';

@Injectable({
  providedIn: 'root'
})
export class PushService {

  notifications:any;
  
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    })
  }
  
  constructor(
    private oneSignal: OneSignal,
    private http: HttpClient,
    private storage: Storage,
    public loadingCtrl: LoadingController,
    public surveyService: SurveyService,
    private platform: Platform,
    private previewFile: PreviewAnyFile,
    private env: EnvService,
    private router:Router,
    private recognizeService:RecognizeService,
    public modalController : ModalController,
    private badge: Badge,
    private toastController:ToastController,
    private zone: NgZone
  ) { }

  initConfig(){

    if (this.platform.is('cordova')) {

      /*if (this.platform.is('android')) {
        this.oneSignal.startInit(this.env.ONE_SIGNAL_APP_ID, this.env.FIREBASE_SENDER_ID);
      }
      if (this.platform.is('ios')) {
        this.oneSignal.startInit(this.env.ONE_SIGNAL_APP_ID);
      }*/

      this.oneSignal.startInit(this.env.ONE_SIGNAL_APP_ID, this.env.FIREBASE_SENDER_ID);

      this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);

      this.oneSignal.handleNotificationReceived().subscribe((data) => {
        // do something when notification is received
        this.badge.increase(1);
        console.log("recibi notificacion in app", data);
      });
    
      this.oneSignal.handleNotificationOpened().subscribe((data) => {
        // do something when a notification is opened
        console.log("abri notificacion ", data);
        this.badge.decrease(1);
        let notification = data.notification.payload.additionalData;

        if(notification.type == 'MENSAJE RECIBIDO' || notification.type == 'RECORDATORIO MENSAJE'){
          this.goMessage(notification);
        }else if(notification.type == 'RECONOCIMIENTO RECIBIDO'){
          this.goRecognize(notification);
        }else if(notification.type == 'REACCION RECONOCIMIENTO'){
          this.goRecognizeReaction(notification);
        }else if(notification.type == 'CARPETA CREADA'){
          this.goFolder(notification);
        }else if(notification.type == 'DOCUMENTO SUBIDO'){
          this.goFile(notification);
        }else if(notification.type == 'ENCUESTA RECIBIDA' || notification.type == 'RECORDATORIO ENCUESTA'){
          let survey_data = notification.data;
          let body = {
            'survey_shippings_id' : survey_data.shipping_id
          }
          this.surveyService.hasSurveys(body).subscribe((response_surveys:any)=>{
            if(response_surveys.status == 200){
              let count = response_surveys.data.count;
              if(count > 0){
                this.goSurvey(notification);
              }else{
                this.showSurveyNotAvailableModal();
              }
            }
          });
          
        }else if(notification.type == 'VOZ DEL EMPLEADO LEIDO' || notification.type == 'VOZ DEL EMPLEADO ATENDIDO'){
          this.viewEmployeeVoice(notification);
        }else{
          this.router.navigate(['/dashboard'])
        }
      });
    
      this.oneSignal.endInit();
      

      // Then You Can Get Devices ID

      this.oneSignal.getIds().then(identity => {
        //console.log(identity.pushToken + " It's Push Token");
        console.log(identity.userId + " It's Devices ID");
        this.storage.set(AppStorageKey.OneSignalUserToken, identity.userId).then(
          (token) => console.log('Token Stored')
        ).catch(err => {
          this.presentToast('Error al guardar el token de notificaciones: '+JSON.stringify(err),'danger');
        });
      }).catch(err => {
        this.presentToast('Error al generar el token de notificaciones: '+JSON.stringify(err),'danger');
      });
    }
  }

  async viewEmployeeVoice(message){

    this.notificationsMarkAs(message.id).subscribe(
      async (data:any)=>{
        console.log("response leido", data);

        let modal= await this.modalController.create({
          component:ViewEmployeeVoicePage,
          componentProps: {
            message: message.data
          }
        }); 
        modal.present();
      }
    );
  }

  async showRecognizeReaction(item){

    let modal= await this.modalController.create({
      component:RecognizeByPage,
      componentProps: {
        'acknowledgment': item
      }
    });
     modal.present()	
  }

  async showSurveyNotAvailableModal(){
    let modal= await this.modalController.create({
      component:SurveyNotAvailableComponent,
    });
    modal.present();
  }

  async showRecognize(recognize,shipping){
    console.log(event);
    let modal= await this.modalController.create({
        component:ReceiveRecognizePage,
        componentProps: {
          'recognize': recognize,
          'shipping':shipping
        }
        //componentProps:{event},
    });
    modal.present()
  }

  goFolder(item){
    this.notificationsMarkAs(item.id).subscribe((data:any)=>{
      if(typeof item['data']['folder'] != 'undefined'){
        let navigationExtras: NavigationExtras = {
          state: {
            folder: item['data']['folder']
          }
        };
        this.router.navigate(['/corp-docs/corp-subdocs'], navigationExtras);
      }
      if(typeof item['data']['subfolder'] != 'undefined'){
        let navigationExtras: NavigationExtras = {
          state: {
            subfolder: item['data']['subfolder'],
            previous_folder: item['data']['subfolder']['folder']
          }
        };
        this.router.navigate(['/corp-docs/corp-subdocs-files'],navigationExtras);
      }
    });
  }

  goFile(item){
    this.notificationsMarkAs(item.id).subscribe((data:any)=>{
      this.openFile(item.file_url);
    });
  }

  openFile(path) {
    if(this.platform.is('desktop') || this.platform.is('mobileweb')){
      window.open(path, '_system');
    } else {
      this.previewFile.preview(path)
        .then((res: any) => console.log(res))
        .catch((error: any) => console.error(error));
    }
  }

  goMessage(item){

    this.notificationsMarkAs(item.id).subscribe(
      (data:any)=>{
        console.log("response leido", data);

        this.modalController.dismiss();
        let navigationExtras: NavigationExtras = {
          state: {
            message: item.data,
            modal:true
          }
        };
        this.router.navigate(['/message/mensaje'],navigationExtras)
      }
    );
  }

  goSurvey(item){
    this.notificationsMarkAs(item.id).subscribe(
      (data:any)=>{
        console.log("response leido", data);
        let navigationExtras: NavigationExtras = {
            state: {
              survey: item.data,
            }
        };
        this.zone.run(async () => {
          await this.router.navigate(['survey/bienvenida'],navigationExtras);
        });
      }
    );
  }

  goRecognizeReaction(item){
    this.showRecognizeReaction(item.data[0]);
  }

  goRecognize(item){
    
    this.notificationsMarkAs(item.id).subscribe(
      async (data:any)=>{
        console.log("response leido", data);

        await this.recognizeService.getRecognizeDetailData(item.data.acknowledgment_id).subscribe(
          (data:any)=>{
            console.log(data);
            if(data.status == 200){
              let acknowledgment = data.acknowledgment;
              let shippings = data.shippings;
    
              if(acknowledgment.count > acknowledgment.necessary_mentions){
                acknowledgment.count = acknowledgment.necessary_mentions;
              }
              
              let shipping = shippings.find(element => element.id == item.data.id);
    
              if(shipping){
                this.showRecognize(acknowledgment,shipping);
              }
    
            }else{
    
            }
          }
        );

      }
    );



  }

  //Obtener data
  getNotifications() {
    return this.http.get(this.env.API_URL + '/notifications/get_list', this.httpOptions)
    .pipe(
      tap(notifications => {
        return notifications;
      }),
      catchError(this.handleError)
    )
  }

  //Obtener data
  notificationsMarkAs(id:any) {
    let data = {
      notification_id: id,
      status:'LEIDA'
    }

    return this.http.post(this.env.API_URL + '/notifications/mark_as', data,this.httpOptions)
    .pipe(
      tap(notifications => {
        return notifications;
      }),
      catchError(this.handleError)
    )
  }

  notificationsAllMarkAs(data:any) {
    return this.http.post(this.env.API_URL + '/notifications/mark_as_list', data,this.httpOptions)
    .pipe(
      tap(notifications => {
        return notifications;
      }),
      catchError(this.handleError)
    )
  }
  
  //marcar como leido
  notificationsSurveyMarkAs(id:any){
    let data = {
      shipping_id: id,
      status:'LEIDA'
    }

    return this.http.post(this.env.API_URL + '/notifications/survey_mark_as', data,this.httpOptions)
    .pipe(
      tap(notifications => {
        return notifications;
      }),
      catchError(this.handleError)
    )
  }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.'
    );
  };

  // Presenta el toast con el error
  async presentToast(msg,color) {
    const toast = await this.toastController.create({
        message: msg,
        duration: 5000,
        position: 'top',
        color: color,
        cssClass: 'toast',
    });
    toast.present();
  }

}
