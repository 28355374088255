import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class EnvService {
  //API_URL = 'http://localhost:8000/api';
  API_URL = 'https://admin.dev.paco.app/api';
  //API_URL = 'https://sandbox.paco.app/api';
  API_CLON = 'https://sandbox.paco.app/api';
  SERVICES_URL = 'http://phpstack-160254-1030070.cloudwaysapps.com/api';
  ONE_SIGNAL_APP_ID = 'd6836b20-bbcb-4b68-9a40-22c0cc3bdfb5';
  FIREBASE_SENDER_ID = '198606707753';
  MAPSKEY = 'pk.eyJ1IjoiamN2aXpjYWlubyIsImEiOiJja2E4Zml1NHowZTkzMnJwaWdiMHlsMjZtIn0.6ciM4BUEDiwrrJi6xqlnPg';

  constructor() { }
}