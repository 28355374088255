import { Component, Input, OnInit } from '@angular/core';
import { ModalController,NavController, ToastController } from '@ionic/angular';
import { RecognizeService } from 'src/app/services/recognize.service';

@Component({
  selector: 'app-recognize-by',
  templateUrl: './recognize-by.page.html',
  styleUrls: ['./recognize-by.page.scss'],
})
export class RecognizeByPage implements OnInit {

  @Input() shipping;
  @Input() acknowledgment;

  constructor(
    private modalController:ModalController,
    public recognizeService:RecognizeService,
    private toastController:ToastController,
    private navCtrl: NavController,
    ) { }

  ngOnInit() {
    console.log("shipping", this.shipping);
    console.log("acknowledgment", this.acknowledgment);
  }

  close(){
    this.modalController.dismiss();
  }

  markReaction(id, reaction){
    console.log("markReaction");
    this.recognizeService.markReaction(id, reaction).subscribe(response => {
      if(response['status'] == 200){
        console.log(response['successMessage']);
        this.presentToast(response['successMessage'],'success');
        this.modalController.dismiss();
        this.navCtrl.navigateRoot(['recognize-team/my-recognizes']);
      }
    });
  }

  // Presenta el toast con el error
  async presentToast(msg,color) {
    const toast = await this.toastController.create({
        message: msg,
        duration: 5000,
        position: 'top',
        color: color,
        cssClass: 'toast',
    });
    toast.present();
  }
}
