import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ImportsPageRoutingModule } from './imports-routing.module';

import { ImportsPage } from './imports.page';
import { SurveyNotAvailableComponent } from '../modals/survey-not-available/survey-not-available.component';
import { ReceiveRecognizePage } from 'src/app/modules/recognize-team/receive-recognize/receive-recognize.page';
import { ViewEmployeeVoicePageModule } from 'src/app/modules/employee-voice/view-employee-voice/view-employee-voice.module';

@NgModule({
  entryComponents: [ReceiveRecognizePage,SurveyNotAvailableComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ImportsPageRoutingModule,
    ViewEmployeeVoicePageModule
  ],
  declarations: [ImportsPage,ReceiveRecognizePage,SurveyNotAvailableComponent]
})
export class ImportsPageModule {}
